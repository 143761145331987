import english from '../data/file/hhs_english.pdf'
import spanish from '../data/file/hhs_spanish.pdf'

export const Forms = (props) => {
  return (
    <div id="about" style={{'paddingTop': '0px'}}>
      <header id='header' className='foo' style={{ 'paddingBottom': '40px'}}>
        <div className='intro' style={{
            'background': `url(${"../../img/forms.jpg"}) center center no-repeat`, 
            'backgroundSize': 'cover',
        }}>
          <div className='overlay'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-8 col-md-offset-2 intro-text'>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header >

      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <div className="about-text">
              <h2>Patient Forms</h2>
              <h3>Please follow the links below to access your forms.</h3>
              <div className="list-style">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <ul>
                    <li>The Open Payments database is a federal tool used to search payments made by drug and device companies to physicians and teaching hospitals. It can be found at <a href="https://openpaymentsdata.cms.gov"><i>https://openpaymentsdata.cms.gov</i>.</a></li>
                    For informational purposes only, a link to the federal Centers for Medicare and Medicaid Services (CMS) Open Payments web page is provided here. The federal Physician Payments Sunshine Act requires that detailed information about payment and other payments of value worth over ten dollars ($10) from manufacturers of drugs, medical devices, and biologics to physicians and teaching hospitals be made available to the public.
                    <br></br>
                    <br></br>
                    <li><a href="https://drive.google.com/file/d/1VGBt1sG_RmGBA0JX5r2jC9FdAjMpLlMH/view?usp=sharing"><i>New Patient Questionnaire - English</i>.</a></li>
                    <li><a href="https://drive.google.com/file/d/1LkdWCidHHfzHs0ysR_-sK3f0r2cBSg99/view?usp=sharing"><i>Acknowledgement Form - English</i>.</a></li>
                    <li><a href="https://drive.google.com/file/d/1im8zLWn6zQS-5sG_1u56TCTKtCbE6PJg/view?usp=sharing"><i>Mobile Phone Form - English</i>.</a></li>
                    <li><a href="https://drive.google.com/file/d/1tf8-XJyH2AONhxgy68X8Eb7dI2tycgur/view?usp=sharing"><i>Financial Policy - English</i>.</a></li>
                    <li><a href={english}><i>Privacy Form - English</i>.</a></li>
                    <br></br>
                    <li><a href="https://drive.google.com/file/d/1WxELZ6DhAn0P_nnOe_D2GSsN3kSYQd6R/view?usp=sharing"><i>New Patient Questionnaire - Spanish</i>.</a></li>
                    <li><a href="https://drive.google.com/file/d/1_PVuLgUVus6uSG1Rl_rEaqVU92Z-NOhU/view?usp=sharing"><i>Acknowledgement Form - Spanish</i>.</a></li>
                    <li><a href="https://drive.google.com/file/d/1KlDKN3hLbevbgXuFoYpCYWMiUM0K2fBr/view?usp=sharing"><i>Mobile Phone Form - Spanish</i>.</a></li>
                    <li><a href="https://drive.google.com/file/d/1EhloycxUMaAdyji2PcxZlrA1DHMEkpLr/view?usp=sharing"><i>Financial Form - Spanish</i>.</a></li>
                    <li><a href={spanish}><i>Privacy Form - Spanish</i>.</a></li>
                    </ul> 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
