export const Procedures = (props) => {
  return (
    <div id="about" style={{'paddingTop': '0px'}}>
      <header id='header' className='foo' style={{ 'paddingBottom': '40px' }}>
        <div className='intro' style={{
            'background': `url(${"../../img/procedures.jpg"}) center center no-repeat`, 
            'backgroundSize': 'cover',
        }}>
          <div className='overlay'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-8 col-md-offset-2 intro-text'>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header >

      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <div className="about-text">
              <h2>Preparing for your Procedures</h2>
              <p>Please follow the instructions provided to you during the office visit or the directions provided on the phone 
                whichever might be applicable in your case. As a general rule, you should not have anything by mouth (NPO) for 
                at least 4 hours before the scheduled procedure time. You will be sedated for your Colonoscopy (or Upper Endoscopy) 
                and will require an escort to drive you home after you are done. If you are unsure about any specific instructions 
                given to you, please call us at <a href="tel:+17606902800"><b>760 690 2800</b></a>.</p>
              <div className="list-style">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                    <ul>
                    <li>Some patients may have difficulty completing the colonoscopy prep – you can make it palatable by refrigerating it and/or adding flavor packs to your prep.</li>
                    <li>It is vital to have a good bowel prep for your colonoscopy to be up to par.</li>
                    <li>For breath tests, please follow instructions outlined in the handout</li>
                    <li>For a Fibroscan appointment, there are no special preparation requirements although it is preferable to do it on an empty stomach.</li>                    
                    <li>For all your imaging (Ultrasound, CT scan, MRI, etc.), please follow the instructions provided by the radiology facility that is doing your test.</li>
                    </ul> 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
