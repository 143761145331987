import { Header } from "../components/header";
import { About } from "../components/about";
import { Philosophy } from "../components/philosophy";

export const Home = (props) => {
  return (
    <div>
      <Header data={props.data.Header} />
      <About data={props.data.About} />
      <Philosophy data={props.data.Philosophy} />
    </div>
  )
}