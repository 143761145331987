export const Payments = (props) => {
  return (
    <div id="about" style={{'paddingTop': '0px'}}>
      <header id='header' className='foo' style={{ 'paddingBottom': '40px' }}>
        <div className='intro' style={{
            'background': `url(${"../../img/payments.jpg"}) center center no-repeat`, 
            'backgroundSize': 'cover',
        }}>
          <div className='overlay'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-8 col-md-offset-2 intro-text'>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header >

      <div className="container" style={{ 'marginBottom': '25px' }}>
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <div className="about-text">
              <h2>Patient Payments</h2>
              <p>If you need to make a payment, please click the link below to be taken to our secure payment form. </p>
              <h3>Questions or concerns?</h3>
              <div className="list-style">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                  <ul>
                    <li key="1">Please call our billing department at <a href="tel:+18585985654"><b>858-598-5654</b></a></li>
                  </ul>
                  <div className='payment-form' style={{ 'paddingTop': '20' }}>
                    <form
                      name='PrePage'
                      method='post'
                      action={process.env.REACT_APP_jb4064B0_9hf93}
                    >
                      <input
                        type='hidden'
                        name='LinkId'
                        value={process.env.REACT_APP_3982h_kASG}
                      />
                      <input
                        type='submit'
                        value='Take me to Authorize.net'
                        className='btn btn-primary'
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
