export const Research = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <div className="about-text">
              <h2>Clinical Research</h2>
              <p>Dr Gara has a google citation index of more than 650. He published several articles in the field of gastroenterology and hepatology from his work at the National Institutes of Health, Washington Hospital Center, Mayo Clinic and the University of California San Diego. He received the prestigious Young Investigator Award from the American Association for Study of Liver Diseases (AASLD). He chaired important sessions at the Annual liver Meeting. His work was selected for invited oral presentations both at the Digestive Diseases Week (DDW – premier meeting of gastroenterologists worldwide) and at the Annual Liver Meeting (premier meeting of hepatologists worldwide). He is an invited reviewer of abstracts submitted to DDW every year. He served as an Assistant Professor at the prestigious and world-renowned Mayo Clinic College of Medicine. Dr Gara also gives Continuing Medical Education (CME) lectures on GI and liver topics to colleagues and allied healthcare providers in different regions of the country. He has been a peer reviewer for several medical journals including the Annals of Internal Medicine, Hepatology, Alimentary Pharmacology & Therapeutics to name a few. He wrote book chapters on both GI and liver topics for recognized medical textbooks such as Schiff’s diseases of the liver. Some of his published articles are listed below:</p>
              
                <div id='row' className=''>
                  {props.data
                    ? props.data.map((d, i) => (
                      <div key={`${d.name}-${i}`} className='col-md-6 col-sm-5 team'>
                        <div className='thumbnail'> 
                          {' '}
                          <img src={d.img} alt='...' className='team-img' />
                        </div>
                      </div>
                    ))
                    : 'loading'}
                </div>
              <div className="list-style">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                  <ul>
                    <li>Gara N, Abdalla A, Rivera E, Zhao X, Werner JM, Liang TJ, Hoofnagle JH, Rehermann B, Ghany MG. Durability of Antibody Response against Hepatitis B Virus in Healthcare Workers Vaccinated as Adults. Clin Infectious Dis. 2015. Feb 15; 60 (4); 505-13; PMID 25389254</li>
                    <li>Gara N, Ghany MG. The Quest For A Cure For Hepatitis C Virus-The End Is In Sight.  The Lancet. 2014 Jun 3; PMID 24907223</li>
                    <li>Gara N, Zhao X, Kleiner DE, Liang TJ, Hoofnagle JH, Ghany MG. Discordance Among Transient Elastography, Aminotransferase to Platelet Ratio Index, and Histologic Assessments of Liver Fibrosis in Patients with Chronic Hepatitis C. Clin Gastroenterol Hepatol. 2012 Nov 7. doi:pii: S1542-3565(12)01312-2. 10.1016/j.cgh.2012.10.044. [Epub ahead of print] PMID: 23142332</li>
                    <li>Gara N, Zhao X, Collins MT, Chong WH, Kleiner DE, Liang TJ, Ghany MG and Hoofnagle JH. Renal Tubular Dysfunction during long-term adefovir or tenofovir therapy in chronic Hepatitis B. Alimentary Pharmacol and Therapeutics. 2012 Jun;35(11):1317-25. PMID: 22506503</li>
                    <li>Gara N, Ghany MG. The new standard of HCV therapy: Retreatment in experienced patients. Clinical Liver Disease. 2012 Feb: 1 (1): 16–19.</li>
                    <li>Gara N, Ghany MG. What the Infectious Disease Physician Needs to Know About Peginterferon and Ribavirin. Clin Infectious Dis. 2013 Mar 1. (epub ahead of print) PMID: 23429551</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <div className="about-text">
              <p>
                With his strong research background, Dr Gara plans to engage in meaningful clinical research in the field of GI & Hepatology that will be of use to his patients in clinical care as well as contribute to advancement of our knowledge in the field.
              </p>
              <p>
                Our ongoing research studies are in the following areas:
              </p>
              <div className="list-style">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                  <ul>
                    <li><a href="https://clinicaltrials.gov/ct2/show/NCT05064553" target="_blank" rel="noreferrer">Hepatocellular carcinoma screening study: ALTUS study</a></li>
                    <li><a href="https://clinicaltrials.gov/ct2/show/NCT04369053" target="_blank" rel="noreferrer">Cancer screening by using liquid biopsy</a></li>
                    <li><a href="https://clinicaltrials.gov/ct2/show/NCT05071716" target="_blank" rel="noreferrer">Hepatic encephalopathy</a></li>
                    <li>Ulcerative colitis</li>
                    <li>Crohn's diseas</li>
                    <li><a href="https://clinicaltrials.gov/ct2/show/NCT03694600" target="_blank" rel="noreferrer">Novel Liver cancer screening biomarker study</a></li>
                    <li><a href="https://clinicaltrials.gov/ct2/show/NCT05353985" target="_blank" rel="noreferrer">Celiac Disease</a></li>
                    <li><a href="https://clinicaltrials.gov/ct2/show/NCT05677971?cond=AATD&phase=2&draw=2&rank=4" target="_blank" rel="noreferrer">Alpha-1-antitrypsin deficiency</a></li>
                    <li><a href="https://classic.clinicaltrials.gov/ct2/show/NCT04822181" target="_blank" rel="noreferrer">NASH</a></li>
                  </ul>
                </div>
                <p>
                  Our upcoming studies will include Primary Biliary Cholangitis, NASH (fatty liver disease), Chronic constipation, etc.
                </p>
                <p>
                  To view Dr Gara’s PubMed profile, please follow this
                  <a href="https://www.ncbi.nlm.nih.gov/pubmed/?term=Gara+N" target="_blank" rel="noreferrer"><i> link</i>.</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
