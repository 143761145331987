export const Philosophy = (props) => {
  return (
    <div id='features' className='text-center' style={{ 'paddingTop': '30px' }}>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2>Our Philosophy & Partners in Care</h2>
        </div>
        <div className='row' style={{ 'paddingBottom': '60px' }}>
          <div key={`1`} className='col-xs-6 col-md-6'>
            {props.data ? props.data.mainParagraph : ''}
          </div>
          <div key={`2`} className='col-xs-6 col-md-6'>Dr Gara has hospital privileges at UCSD Hospitals(La Jolla & Hillcrest),
            <a
              href="https://www.palomarhealth.org/locations/location-details?practice=4"
            > Palomar Health </a>
            in Escondido and
            <a
              href="https://www.palomarhealth.org/locations/location-details?practice=7"
            > Pomerado Hospital </a>
            in Poway. Our goal is to
            provide academic level care in the community. He also performs endoscopy procedures at
            Parkway Endoscopy Center in Escondido and Palomar Health in Escondido.
          </div>
        </div>
      </div>
    </div>
  )
}
