import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { Navigation } from "./components/navigation";
import { Home } from './components/home';
import { Forms } from "./components/forms";
import { Contact } from "./components/contact";
import { Research } from './components/research';
import { Payments } from './components/payments';
import { Symptoms } from './components/symptoms';
import { Procedures } from './components/procedures';
import { Team } from './components/team';
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import ScrollToTop from '../src/components/scrollToTop'
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Router>
        <ScrollToTop>
          <div>
            <Navigation />
            <Routes>
              <Route path="/" element={<Home data={landingPageData} />} />
              <Route path="/research" element={<Research data={landingPageData.Research} />} />
              <Route path="/common-symptoms" element={<Symptoms />} />
              <Route path="/procedures" element={<Procedures />} />
              <Route path="/forms" element={<Forms />} />
              <Route path="/payments" element={<Payments />} />
              <Route path="/team" element={<Team data={landingPageData.Team} />} />
            </Routes>
          </div>
        </ScrollToTop>
      </Router>
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default App;
