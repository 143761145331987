export const Symptoms = (props) => {
  return (
    <div id="about" style={{'paddingTop': '0px'}}>
      <header id='header' className='foo' style={{ 'paddingBottom': '40px' }}>
        <div className='intro' style={{
            'background': `url(${"../../img/header-old.jpg"}) center center no-repeat`, 
            'backgroundSize': 'cover',
        }}>
          <div className='overlay'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-8 col-md-offset-2 intro-text'>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header >

      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <div>
              <h2>Diseases We Treat</h2>
              <p>
                Dr Gara is a well-rounded gastroenterologist with interests and expertise both in gastrointestinal and liver conditions.GI & Liver diseases can present with myriad 
                symptoms and more importantly, can be asymptomatic for a long time. The following questions and answers will help address some of your concerns. This is not meant to 
                be an exhaustive list but rather a general outlook of the common symptoms we encounter in our clinic:
              </p>
                <div className="list-style">
                  <div className="col-lg-12 col-sm-12 col-xs-12">
                    <ol>
                      <li><h4>Why do I have so much heartburn?</h4></li>
                      <p>
                        While stomach acid is an essential component of human digestive system, some of us can develop bothersome heartburn either from excessive acid production or due to a 
                        change in the physiologic relationship between the esophagus (food pipe) and stomach. While Gastroesophageal Reflux Disease (GERD) is the most common disease associated 
                        with heartburn, there might be other diseases that can manifest as such. During your consultation, we will try and tease out the probable cause in your case. Long-term GERD
                        can lead to precancerous changes in the lining of the esophagus with some progressing to cancer. An Upper endoscopy (EGD) can detect these changes early in their course and 
                        help prevent cancer.
                      </p>

                      <li><h4>I have trouble swallowing food. What could be the issue?</h4></li>
                      <p>
                        There can be several reasons that cause dysphagia (trouble swallowing food). We will try and differentiate your symptoms to identify the cause. If we suspect that there might be an Esophageal cause for your symptoms, we will perform an Upper Endoscopy. The exact cause can be GERD in some cases while the most worrisome cause is Esophageal cancer. Longstanding GERD can sometimes lead to strictures (narrowing of the lumen) that can be dilated (stretched open) during the endoscopy. Some patients can have an inflammatory condition known as Eosinophilic Esophagitis which can be controlled with treatment.
                      </p>

                      <li><h4>My stomach hurts all the time and I am unable to eat much. Is there a remedy?</h4></li>
                      <p>
                        While we might feel that the “stomach” hurts, the pain may not be coming from the stomach. However, there are significant stomach problems that can cause pain and 
                        discomfort with eating. One of the most common causes is a bacterial infection of the stomach called Helicobacter pylori (H.pylori). An Upper Endoscopy with stomach biopsy 
                        can help diagnose this condition. There is effective treatment for it. If left untreated, long-term H.pylori infection can lead to stomach cancer. Pain arising from the 
                        pancreas (located behind the stomach) can also be a source of “stomach pain”. If we suspect this to be the case, an imaging test of the pancreas may be needed. 
                      </p>

                      <li><h4>I have a lot of bloating and I always feel gassy. I have seen several doctors but the problem persists. Are we missing something?</h4></li>
                      <p>
                        Gas and bloat symptoms can be very frustrating. They can be a reflection of a variety of diseases such as GERD, Peptic Ulcer disease, Small Intestinal problems such as 
                        bacterial overgrowth, malabsorption or even diseases of the colon (large intestine) to name a few. During your consultation, our goal is to narrow the list of possibilities
                        tailor testing and treatment to your particular scenario. 
                      </p>

                      <li><h4>I see blood in the stools</h4></li>
                      <p>
                        This is perhaps one of the most worrying GI symptoms. Blood in the stools can be mixed with the stools or seen upon wiping. In other cases, you may not see the actual blood 
                        but might have tested positive for a stool test that detects microscopic amounts of blood. Our job is to determine the underlying source of blood in the stools that could be 
                        anything from hemorrhoids to inflammation of the lining of the colon to colon cancer. A colonoscopy is an examination of the lining of the colon that can help determine the 
                        cause.
                      </p>

                      <li><h4>What is a Screening Colonoscopy?</h4></li>
                      <p>
                        Colonoscopy is an endoscopic examination (a test that uses a flexible camera to look at the inside of the colon i.e. the large intestine). Cancer of the colon is the 3rd leading 
                        cause of cancer-related death in both men and women in the USA. It can affect all races. Each year, several thousands of people die from Colon cancer in the USA itself. Screening 
                        colonoscopy is intended to detect colon cancer at an early stage and better yet, prevent colon cancer by detecting polyps (some of which are precancerous lesions) that can be removed 
                        during the colonoscopy. The age at which one should start having screening colonoscopies is at age 45 per the American Cancer Society. This used to be age 50. In some cases, screening 
                        is recommended even earlier if there is a higher risk based on family history of colon cancer, certain cancer syndromes or other history of diseases such as Ulcerative Colitis or 
                        Crohn’s Colitis. Though there are other modalities for colon cancer screening, a colonoscopy remains the gold standard because of its ability to directly visualize lesions and the 
                        capability to remove precancerous lesions at the same time.
                      </p>

                      <li><h4>How often should I have a Colonoscopy after my first screening exam?</h4></li>
                      <p>
                      If there are no polyps detected on your index colonoscopy and you have no family history of increased risk (such as colon cancer or precancerous polyps), then the next 
                                colonoscopy is recommended in 10 years unless there are technical factors during the test that necessitate a shorter interval. If you had precancerous polyps detected 
                                on your index colonoscopy, a shorter follow-up is indicated and the exact time interval depends on the number and type of polyps.
                      </p>

                      <li><h4>I feel absolutely fine but my doctor tells me I have liver disease</h4></li>
                      <p>
                      Chronic liver disease typically is a smoldering problem that can remain undiagnosed for decades because it does not cause symptoms until it is in advanced stages. 
                                Some patients might have abnormal liver tests noticed on routine blood tests that can lead to the diagnosis but many others might have normal labs but still have chronic 
                                liver disease. 
                      </p>

                      <li><h4>What is cirrhosis?</h4></li>
                      <p>
                      Cirrhosis is the most advanced from of chronic liver disease that is the result of several years of liver damage that leads to fibrosis and scarring. Even with cirrhosis, 
                                a lot of patients can go undiagnosed until they decompensate. However, as opposed to what was once taught, this is not an irreversible process. If timely interventions are 
                                made, cirrhosis can be effectively managed. Thus, it is important to have a trained hepatologist take care of your liver disease.
                      </p>

                      <li><h4>I have Hepatitis C. I want to get treated</h4></li>
                      <p>
                      Chronic Hepatitis C is a common cause of liver disease in the USA. In some populations, the prevalence is as high as 10% of the population. Untreated Hepatitis C can lead 
                                to cirrhosis and liver cancer. Historically, treatment of hepatitis C was risk-prone and only marginally effective. However, we now have effective treatments that can 
                                achieve “cure” of hepatitis C infection with only a few weeks of therapy and a greater than 95% chance of success. Dr Gara specializes in the treatment of hepatitis C, has 
                                done important research in this field and wrote opinion pieces pertaining to new hepatitis C treatments in reputed international journals. Gastroenterology & Liver 
                                Institute is the place to be for hepatitis treatment.
                      </p>

                      <li><h4>I have Hepatitis B. Do I need treatment?</h4></li>
                      <p>
                      Chronic Hepatitis B is a major problem worldwide. It is a leading cause of liver cancer. There are some sub-populations in the USA that are at increased risk due to higher 
                                prevalence of Hepatitis B in their countries of origin particularly Asian, African and some Latin American. There are different phases of hepatitis B infection and treatment 
                                is indicated in some phases while in other phases close monitoring is recommended. Dr Gara is a recognized expert in Hepatitis B treatment. He is the author of the chapter on 
                                Hepatitis B & D in the premier liver diseases textbook, Schiff’s diseases of the Liver.
                      </p>

                      <li><h4>I have Fatty Liver disease. Are there any treatment options?</h4></li>
                      <p>
                      Nonalcoholic fatty liver disease or fatty liver disease as it is commonly known is a rapidly growing public health problem that is closely tied to the obesity epidemic. There are 
                                also genetic risks to this disease with Hispanic populations having the highest disease prevalence in the USA. While lifestyle changes are the cornerstone of treatment, specialized 
                                hepatology care is important so as to recognize the extent of disease, diagnose disease complications in a timely manner and prevent further deterioration. At Gastroenterology & Liver 
                                Institute, our goal is also to engage in fatty liver disease research so we can bring drugs in development to our patients that would benefit from them.
                      </p>

                      <li><h4>What is a Fibroscan?</h4></li>
                      <p>
                      Traditionally, a liver biopsy was the only method available that could stage liver disease severity and provide useful diagnostic information. Fibroscan (transient elastography) 
                      is a novel tool that utilizes modern technology to provide accurate staging of liver fibrosis without having to obtain a biopsy. It is an office-based tool with results obtained 
                      instantaneously. There is no pain because it is a non-invasive method.
                      </p>

                      <li><h4>Constipation has always been a constant problem all my life</h4></li>
                      <p>
                      There is a wide range of normal bowel movements depending on the individual. However, there are some of us who fall outside this range and can have a bothersome daily life because of 
                      constipation. Our goal is to make sure we are not missing underlying serious pathology causing constipation and then focus on symptomatic treatment to help regularize bowel movements. 
                      Management would include education, lifestyle changes and not limited to prescribing laxatives.
                      </p>

                      <li><h4>Diarrhea severely limits my lifestyle</h4></li>
                      <p>
                      There is a wide range of normal bowel movements depending on the individual. However, there are some of us who fall outside this range and can have a bothersome daily life because of diarrhea. 
                      In some cases, diarrhea can be a manifestation of underlying chronic diseases such as inflammatory bowel disease or malabsorption. In other cases, it can represent irritable bowel syndrome. 
                      Our goal is to accurately identify the cause and tailor treatment to your case.
                      </p>

                      <li><h4>I have heard about new weight loss procedures</h4></li>
                      <p>
                      During his work at Mayo clinic, Dr Gara collaborated on protocols that involved novel bariatric endoscopic techniques for weight loss. At GI & Liver Institute, our goal is to inculcate these 
                      modalities in to our practice to help treat morbid obesity non-invasively.
                      </p>

                      <li><h4>I do not fit in to a particular pattern but have multitude of symptoms</h4></li>
                      <p>
                      It is not uncommon to have more than one disease process playing a role at the same time. Our job is to tease out the symptoms and try to identify the underling pathology. As a Gastroenterologist 
                      with a rich training background varied work experience, Dr Gara has the required acumen to diagnose and manage complex and even rare diseases that do not quite fit an established pattern.
                      </p>
                    </ol> 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
